.leaderboard{
    height: 100vh;
    padding-top: 50px;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display: none;
    }
    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        p{
            padding: 5px 15px;
            background-color: #fff;
            color: #000;
            border-radius: 8px;
            margin-bottom: 0;
        }
    }
    .ant-table-pagination{
        justify-content: center;
    }
}
.leaderboard::-webkit-scrollbar{
    display: none;
}