
.crepal{
    
    .layer1{
        // height: 400px;
        width: 100%;
        position: relative;
        padding: 30px 30px 20px;
        @media (max-width:768px) {
            padding: 30px 20px 20px;
        }
        p,h5{
            color: #000;
            margin-bottom: 0;
        }
        .textdisplay{
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 60%;
           
            .ant-image{
                margin-bottom: 12px;
            }
            h5{
                font-weight: 650;
                font-size: 15px;
            }
            .check{
                width: 60%;
                margin-bottom: 10px;
            }
            .ant-btn{
                border-radius: 10pc;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
            }
            @media (max-width:768px) {
                width: 100%;
                .ant-btn{
                    width: 65%;
                }
            }
        }
        .cardImage{
            position: absolute;
            bottom: 10px;
            right: 25px;
        }
    }
    .layer2{
        height: 101px;
        background-color: #fff;      
        width: 100%;       
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            color: #000;
            text-decoration: underline;
            font-size: 13px;
            font-weight: 500;
        }  
    }
    .layer3{
        width: 100%;
        height: 66px;
        background-color: #121E2C;
        display: flex;
        align-items: center;
        .left{
            width: 50%;
            height: 100%;
            border-right: 1px solid #fff;
        }
    }
    .layer4{
        // height: 368px;
        width: 100%;
        padding: 20px;
        background-color: #fff;
        text-align: center;
        padding-bottom: 80px;
        p,h5{ 
            color: #000;
            margin-bottom: 0;
        }
        h5{
            font-size:16px;
            font-weight: 600;
        }
        @media (max-width:768px) {
            padding: 15px;
        }
        .percentage{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 100px;
            .percentdetails{
                display: flex;
                flex-direction: column;
                gap: 0px;
                align-items: flex-start;
                position: relative;
                hr{
                    width: 50%;
                    color: #2565B0;
                    position: absolute;
                    left: 0;
                    top: 60%;
                    border: 1.5px solid #2565B0;
                }
                .each{
                    text-align: left;
                    font-size: 12px;
                    font-weight: 400;
                    width: 70%;
                }
                .top{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    .number{
                        font-size: 60px;
                        font-weight: 600;
                    }
                    .cashpercent{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .cashpercent span:first-child{
                        font-size: 27.5px;
                        font-weight: 600;
                    }
                    .cashpercent span:nth-child(2){
                        font-weight: 600;
                        margin-top: -10px;
                    }
                }
            }
            @media (max-width:768px) {
                padding-left: 10px;
                .percentdetails{
                    justify-content: space-between;
                    hr{
                        top: 52%;
                    }
                }
            }
        }
    }
    .layer5{
        // height: 456px;
        position: relative;
        background-color: #FFF9F9;
        width: 100%;
        padding: 40px 0;
        p,h5,h6{
            color: #000;
            margin-bottom: 0;
        }
        .view{
            display: flex;
            align-items: center;
            z-index: 2;
            justify-content: center;
            width: 100%;
            @media (max-width:768px) {
                
            }
            .viewleft{
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                @media (max-width:768px) {
                    width: 0%;
                    .ant-image{
                        display: none;
                    }
                }
            }
            .viewright{
                width: 60%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-right: 60px;
                .ant-btn{
                    border-radius: 10pc;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    margin-bottom: 10px;
                }
                a{
                    color: #000;
                    text-decoration: underline;
                    font-size: 13px;
                    font-weight: 400;
                }  
                h5{
                    font-weight: 700;
                    font-size: 12px;
                }
                h6{
                    font-weight: 400;
                    margin-bottom: 5px;
                    width: 85%;
                }
                p.first{
                    font-size: 14px;
                    font-weight: 600;
                    width: 70%;
                    margin-bottom: 10px;
                }
                p.sec{
                    font-size: 12px;
                    margin-bottom: -10px;
                }
                @media (max-width:768px) {
                    width: 100%;
                    padding: 20px;
                    z-index: 2;
                    .ant-btn{
                        width: 70%;
                    }
                }
            }
        }
        .cardImage1{
            position: absolute;
            bottom: -45px;
            left: 0;
            z-index: 1;
        }
        .cardImage2{
            position: absolute;
            bottom: -70px;
            left: 0;
            z-index: 0;
        }
    }
    .layer6{
        position: relative;
        background-color: #fff;
        // height: 450px;
        width: 100%;
        padding: 40px 0;
        display: flex;
        align-items: center;
        z-index: 1;
        p,h4{
            color: #000;
        }
        .left{
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            // padding: 20px;
            .blueimgback{
                border-radius: 10px;
                height: 142px;
                width: 256px;
                padding: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                p.five{
                    color: #2565B0;
                    font-size: 70px;
                    font-weight: 500;
                    margin-bottom: 0;
                    margin-top: -20px;
                }
                .percbac{
                    display: flex;
                    flex-direction: column;
                }
                .percbac span:first-child{
                    font-size: 30px;
                    font-weight: 600;
                    color: #2565B0;
                    margin-bottom: -10px;
                }
                .percbac span:nth-child(2){
                    color: #fff;
                    font-size: 16px;
                    letter-spacing: 1px;
                    line-height: 20px;
                }
            }
        }
        .right{
            width: 60%;
            h4{
                font-size: 16px;
                font-weight: 700;
            }
            p{
                width: 70%;
                font-family: 14px;
                line-height: 20px;
            }
        }
        @media (max-width:768px) {
            flex-direction: column;   
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 20px;
            .left,.right{
                width: 100%;
            }
            .right{
                h4{
                    text-align: center;
                }
                p{
                    width: 100%;
                    font-family: 14px;
                    line-height: 20px;
                    text-align: center;
                }
            }
        }
    }
    .layer7{
        width: 100%;
        background-color: #FFF9F9;
        // height: 400px;
        padding: 40px 60px;
        h3{
            color: #000;
            text-align: center;
            margin-bottom: 40px;
            font-weight: 650;
        }
        h5,p{
            color: #000;
        }
        .gridlayout{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            .griditem{
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                .ant-image{
                    height: 50px;
                }
                hr{
                    width: 40%;
                    color: #2565B0;
                    // position: absolute;
                    // left: 0;
                    // top: 60%;
                    border: 2px solid #2565B0;
                    margin: 20px 0;
                }
                h5{
                    font-weight: 700;
                }
                p{
                    text-align: center;
                    margin-top: -5px;
                    line-height: 20px;
                    font-size: 14px;
                    width: 70%;
                }
            }
        }
        @media (max-width:768px) {
            padding: 40px 20px;
            .gridlayout{
                grid-template-columns: 1fr 1fr;
                .griditem{
                    h5{
                        text-align: center;
                    }
                    p{
                        width: 100%;
                    }
                }
            }
        }
    }
    .layer8{
        width: 100%;
        background-color: #fff;
        padding: 60px 10px 60px 100px;
        p,h4,h3{
            color: #000;
        }
        h3{
            text-align: center;
            margin-bottom: 30px;
            font-weight: 600;
        }
        .gridlayout{
            display: grid;
            grid-template-columns: 1fr 1fr;
            .griditem{
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 5px;
                p{
                    width: 80%;
                    font-size: 14px;
                }
                h4{
                    font-weight: 700;
                    font-size: 14px;
                }
                .anticon{
                    position: absolute;
                    left: -25px;
                    top: 6px;
                }
            }
        }
        @media (max-width:768px) {
            padding: 20px 0 40px 40px;
            .gridlayout{
                .griditem{
                    p{
                        width: 95%;
                    }
                }
            }
        }
    }
    .layer9{
        background-color: #FFF9F9;
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        padding: 40px 60px;
        .left{
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 40%;
            h2{
                line-height: 30px;
                font-size: 22px;
                font-weight: 700;
            }
            .ant-btn{
                border-radius: 10pc;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40%;
            }
            h6{
                font-size: 10px;
                line-height: 15px;
                font-weight: 400;
                margin-top: 15px;
            }
        }
        .left p:nth-child(2){
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 35px;
        }
        .right{
            width: 50%;
            position: relative;
            height: 100%;
            .image1{
                position: absolute;
                bottom: -40px;
                left: -20px;
            }
            .image2{
                position: absolute;
                top: 80px;
                right: 0px;
            }
        }
        p,h2,h6{
            color: #000;
        }
        @media (max-width:768px) {
            flex-direction: column;
            padding: 20px;
            .left,.rigth{
                width: 100%;
            }
            .right{
                .image1, .image2{
                    display: none;
                }
            }
        }
    }
}