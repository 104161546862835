.variants {
    .type {
      font-size: 14px;
    }
    .oi::-webkit-scrollbar {
      display: none;
    }
    .variant-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-height: 150px;
      overflow: scroll;
      .variant {
        border: 1px solid rgb(217, 217, 217);
        // width: 140px;
        // h eight: 30px;
        padding: 10px;
        font-size: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px;
        cursor: pointer;
        opacity: 0.9;
      }
      .variant:hover {
        opacity: 1;
      }
      .selected {
        background: #D90068;
        color: white !important;
      }
    }
    .variant-wrapper::-webkit-scrollbar{
      display: none;
    }
    .clear-button {
      float: right;
      background: #D90068;
      color: #fff;
    }
  }
  .custquantity{
    display: flex;
    flex-direction: column;
    .custom-wrapper{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5px;
      .custom-quat {
        border: 1px solid rgb(217, 217, 217);
        // width: 140px;
        // h eight: 30px;
        padding: 10px;
        font-size: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px;
        cursor: pointer;
        opacity: 0.9;
        transition: scale 1s ease;
      }
      .custom-quat:hover {
        opacity: 1;
        scale: 1.05;
      }
    }
  }
  .ant-tabs{
    color: #fff;
  }
  .ant-tabs-tab-btn{
    color: #fff;
  }
  .ant-tabs-tab-btn:focus{
    color: #4c4a4a !important;
  }
  .branch-list{
    color: #000;
  }
  .branch-wrapper {
    display: flex;
    flex-wrap: wrap;
    // max-height: 190px;
    height: fit-content;
    // overflow: scroll;
    .branch {
      border: 1px solid rgb(217, 217, 217);
      // width: 140px;
      // h eight: 30px;
      padding: 10px;
      font-size: 14px;
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 10px;
      cursor: pointer;
      opacity: 0.9;
    }
    .branch:hover {
      opacity: 1;
    }
  }
  .select-branch{
    position: fixed;
    top: 80px;
    right: 10%;
    left: 10%;
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;
    z-index: 1;
  }
  .display_credit{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .booking{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .bookingbtn{
      button{
        background-color: #fff;
        color: #000;
        height: 52px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @media (max-width:768px) {
      h2{
        text-align: center;
      }
    }
  }
  @media (max-width: 600px) {
    .select-branch{
      right: 3%;
      left: 3%;
    }
  }