
.md_2{
    .btn-div{
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-btn{
            width: 100%;
            height: 40px !important;
            border-radius: 8px;
            span{
                color: #fff;
            }
        }
    }
    .btn-div1{
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-btn{
            width: 40%;
            height: 40px !important;
            border-radius: 8px;
            span{
                color: #fff;
            }
        }
    }
}