.book_filled {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border: 5px solid #fff;
    border-radius: 100px;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .book_ {
        font-size: 40px;
        color: #fff;
        cursor: pointer;
        border-radius: 100px;
    }
}

.opacity_50 {
    opacity: 0.5;
}