.banner_details {
	height: 100%;
	width: 90%;
	margin: 20px 0;

	@media (max-width: 768px) {
		margin: 20px auto !important;
	}
}
span.ant-radio + *{
	color: #fff !important;
}
.ant-radio-disabled + span {
    color: rgba(255, 255, 255, 0.25) !important;
}
.banner_text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	p {
		max-width: 373px;
		padding: 20px;
	}
	p ~ p {
		padding-top: 0;
	}
	.button_div {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 60px;
		margin-right: 40px;
	}
}
.variants p{
	color: #000;
}


.counter_div {
	width: 90%;
	margin: 20px 5%;
}

.whats_trending {
	margin-top: 50px;
	display: flex;
	justify-content: space-between;

	.carousel_cont {
		width: 50%;
	}
	.trending_details {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	@media (max-width: 768px) {
		flex-direction: column-reverse;
		.carousel_cont {
			width: 100%;
		}
		.trending_details {
			margin-top: 30px;
		}
	}
}
.ant-modal.login_modal {
	.ant-modal-close-x {
		margin: 30px 0 0 0;
	}
	.ant-modal-content {
		border-radius: 24px 24px 0 0;
		padding: 20px 20px 20px 0;
	}
	.discover_details {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 30px;
		margin-left: 10px;
		.discover_text {
			margin-left: 20px;
			p {
				font-weight: 600;
				font-size: 14px;
				margin: 0;
				color: #000;
			}
			p ~ p {
				font-weight: 400;
				font-size: 14px;
				color: #6b6a6a;
				width: 80%;
			}
		}
	}
	.action_buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 40px;
		button {
			font-size: 14px;
		}
	}
	.sign_up_content {
		margin-top: 20px;
		margin-left: 10px;
		padding: 20px;

		svg {
			color: #6b6a6a;
			margin-right: 10px;
		}
		.ant-picker-content {
			background: #6b6a6a;
		}
	}

	.ant-table-cell {
		padding: 0;
	}
	.ant-card-hoverable .ant-card-body {
		padding: 8px;
	}
}
.merchant_spin{
	.ant-spin-dot.ant-spin-dot-spin{
		.ant-spin-dot-item{
			color: #fff;
			background-color: #fff;
		}
	}
}


.multi_loyalty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	// align-items: center;
	margin-top: 20px;
	.loyaltyDiv{
		display: flex;
		flex-direction: row;
		gap: 5px;
		@media (max-width:768px) {
			flex-direction: column;
		}
		.img_banner {
			height: 343px;
			width: 50%;
			@media (max-width: 768px) {
				width: 100%;
			}
		}
		.ant-carousel{
			width: 50%;
			height: 343px;
			.upperpose{
				position: absolute;
				top: 0;
				background-color: rgba(0, 0, 0, 0.71);
				width: 100%;
				padding: 0 20px;
				// height: 90px;
				display: flex;
				flex-direction: column;
			}
			@media (max-width:768px) {
				width: 100%;
			}
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
	}
}
.cart_action{
	cursor: pointer;
	transition: all 0.3s ease;
}
.cart_action:hover{
	color: rgba(112, 104, 104, 0.4);
}
.ant-btn.cart_button{
	color: #fff;
	width: 80%;
	background-color: rgb(255,69,0);
	height: 50px;
	transition: all 0.5s ease;
	border: none;
}
.ant-btn.cart_buttons{
	color: #fff;
	width: 80%;
	background-color: rgb(0,0,0);
	height: 50px;
	transition: all 0.5s ease;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}
.ant-btn.cart_button:hover{
	background-color: rgb(255,99,71);
}

.ant-modal.profile_modal {
	margin-top: -7.5rem;
	padding: 25px;
	.ant-modal-close-x {
		margin: 20px 0 0 0;
		color: #fff;
	}
	.ant-modal-content {
		// border-radius: 24px 24px 0 0;
		border-radius: 0;
		padding: 20px;
		background-color: #131313;
		border: 1px solid #000;
	}
	.sign_up_content {
		// margin-top: 20px;
		margin-left: 10px;
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		

		svg {
			color: #6b6a6a;
			margin-right: 10px;
		}
		.ant-picker-content {
			background: #6b6a6a;
		}
	}

	.contact_address {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		.contact,
		.address {
			border-top: 1px solid #fff;
			border-bottom: 1px solid #fff;
			padding: 20px 35px;

			& > p:first-child {
				color: #6b6a6a;
				font-weight: 600;
				font-size: 16px;
			}
		}
		.contact {
			border-right: 0.5px solid #fff;
		}
		.address {
			border-left: 0.5px solid #fff;
		}
	}
	.logout {
		width: 45%;
		// border-top: 1px solid #fff;
		padding-top: 10px;
		margin-top: 20px;
	}
}

.cart_icons{
	cursor: pointer;
	display: inline;
	transition: all 0.3s ease;
}
.cart_icons:hover{
	color: rgb(65, 63, 63);
}
.item_variant{
	color: #000;
	border: 1px solid #000;
	font-size: 14px;  
	padding: 0.5rem;
	transition: all 0.5s ease;
}
.item_variant:hover{
	cursor: pointer;
	background-color: rgb(58, 56, 56);
}
.item_variant:current{
	background-color: #000;
}
.btn_change{
	cursor: pointer;
}
.ant-modal.variants{
	color: #000;
	margin-top: -5rem;
	.ant-modal-header{
		background-color: #6b6a6a;	
		
	}
	.ant-modal-close-x {
		margin: 0;
		color: #000;
	}
	.ant-modal-title{
		color: #000;
	}
	.product{
		background-color: #6b6a6a;
	}
	.ant-modal-body{
		background-color: #6b6a6a;
		.ant-btn{
			background-color: #5f5e5e;
			border: none;
			color: #000;
		}
		.ant-btn:hover{
			color: #7a7878;
		}
		
	}
	.ant-modal-content{
		background-color: #6b6a6a;
	}
	.ant-modal-footer{
		.ant-btn{
			background-color: #5f5e5e;
			border: none;
			color: #000;
		}
		.ant-btn:hover{
			color: #7a7878;
		}
	}
}

.ant-modal.description_modal{
	color: #000;
	padding: 0 2rem;
	margin-top: -2rem;
	.ant-modal-close-x {
		margin: 0;
		color: #000;
	}
	.ant-modal-header{
		border-radius: 25px 25px 0 0;
	}
	.ant-modal-body{
		border-radius: 25px 25px 0 0;
		margin: -2.5rem -1.5rem -1.3rem -1.5rem;
		p, h2{
			color: #000;
		}
		img{
			width: 300px;
			height: 300px;
		}
		.div_body{
			margin: 1rem 0 0 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			// justify-content: space-between;
			.ant-carousel{
				height: 300px;
				width: 300px;
			}
		}
	}
	.ant-modal-content{
		color: #000;
		margin-top: -1rem;
	}
	.ant-modal-content{
		border-radius: 25px 25px 25px 25px;
	}
	.ant-modal-footer{
		border-radius: 0 25px 25px 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: -1.5rem 0 -0.5rem ;
		.p_div{
			margin-top: -0.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			p{
				margin-bottom: 0rem;
			}
		}
	}
}
.add_icon_footer{
	cursor: pointer;
}
.add_icon_footer:hover{
	background-color: #fff;
}
.ant-modal.checkout_modal{
	color: #000;
	padding: 0 1rem;
	margin-top: -5rem;
	.ant-modal-close-x {
		margin: 0;
		color: #000;
	}
	.ant-modal-body{
		border-radius: 25px 25px 0 0;
		p, h2{
			color: #000;
		}
	}
	.ant-modal-content{
		border-radius: 25px 25px 25px 25px;
	}
	.ant-modal-footer{
		border-radius: 0 25px 25px 25px;
	}
}
.ant-modal.checkout_modal1{
	color: #000;
	padding: 0 1rem;
	// margin-top: -5rem;
	.ant-modal-close-x {
		margin: 0;
		color: #000;
	}
	.ant-modal-body{
		border-radius: 25px 25px 0 0;
		p, h2{
			color: #000;
		}
	}
	.ant-modal-content{
		border-radius: 25px 25px 25px 25px;
	}
	.ant-modal-footer{
		border-radius: 0 25px 25px 25px;
	}
}
.ant-modal.delivery_modal{
	color: #000;
	padding: 0 1rem;
	margin-top: -2rem;
	.ant-modal-content{
		border-radius: 25px 25px 25px 25px;
	}
	.ant-modal-header{
		border-radius: 25px 25px 0 0;
	}
}
.ant-form.ant-form-vertical{
	.ant-form-item{
		margin-bottom: 0.5rem;
		.ant-form-item-row{
			display: flex;
			flex-direction: column;
			gap: -1rem;
		}
	}
}

.ant-modal.payment_code{
	color: #000;
	padding: 0 1rem;
	margin-top: 3rem;
	.ant-modal-content{
		border-radius: 25px 25px 25px 25px;
	}
	.ant-modal-header{
		border-radius: 25px 25px 0 0;
	}
	.ant-modal-body{
		display: flex;
		flex-direction: column;
		text-align: center;
		gap: 1rem;
		margin-top: -0.8rem;
		margin-left: 0.7rem;
		h4{
			text-align: center;
			color: #000;
			line-height: 15px;
			height: 10px;
			margin-bottom: 0.6rem;
		}
		.checkbox_div{
			width: 90%;
			margin-left: 10%;
			text-align: left;
			display: flex;
			gap: 0.2rem;
			p{
				color: #000;
				margin-bottom: 0rem;
				font-weight: 600;
			}
		}
		img{
			height: 60px;
			width: 60px;
		}
		p{
			color: #000;
			margin-bottom: 0rem;
		}
		button{
			color: #fff;
			width: 80%;
			background-color: #000;
			height: 50px;
			transition: all 0.5s ease;
			border: none;
		}
	}
}
.ant-modal.bank_modal{
	color: #000;
	padding: 0 1rem;
	margin-top: -5.5rem;
	.ant-modal-content{
		border-radius: 25px 25px 0 0;
	}
	.ant-modal-body{
		display: grid;
		grid-template-columns: 30% 30% 30%;
		grid-gap: 1rem;
		text-align: center;
		margin-top: -1.3rem;
		margin-left: 0.7rem;
		.bank_codes{
			cursor: pointer;
			h4{
				text-align: center;
				color: #000;
				line-height: 15px;
				height: 23px;
				margin-bottom: 0.6rem;
			}
			img{
				height: 60px;
				width: 60px;
			}
			.ussd_modal{
				display: flex;
				text-align: center;
				justify-content: center;
				margin-top: 0.3rem;
			}
			p{
				color: #000;
				margin-bottom: 0rem;
			}
		}
	}
	.ant-modal-header{
		border-radius: 25px 25px 25px 25px;
	}
	.ant-modal-footer{
		border-radius: 0 25px 25px 25px;
	}
}
.ant-modal.success_modal{
	.ant-modal-wrap{
		margin: 0;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 7rem;
	padding: 0 1rem 0 1rem;
	width: "300px";
	color: #000;
	.ant-modal-close-x {
		margin: 0;
		color: #000;
	}
	.ant-modal-close-x:hover{
		color: #000;
	}
	.ant-modal-content{
		background-color: #fff;
		color: #000;
		p{
			color: #000;
		}
		// margin-top: 5%;
	}
	.ant-modal-body{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		h4, p{
			color: #000;
		}
	}
	.ant-modal-footer{
		background-color: #fff;
		border-top: none;
		.ant-btn{
			background-color: #000;
			border: none;
			color: #fff;
		}
		.ant-btn:hover{
			color: #7a7878;
		}
	}
}
.ant-modal.sure_modal{
	.ant-modal-wrap{
		margin: 0;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 7rem;
	padding: 0 1rem 0 1rem;
	width: "300px";
	color: #000;
	.ant-modal-close-x {
		margin: 0;
		color: #fff;
	}
	.ant-modal-close-x:hover{
		color: #000;
	}
	.ant-modal-content{
		background-color: #fff;
		color: #000;
		p{
			color: #000;
		}
		// margin-top: 5%;
	}
	.ant-modal-body{
		h4, p{
			color: #000;
		}
	}
	.ant-modal-footer{
		background-color: #fff;
		border-top: none;
		.ant-btn{
			background-color: #000;
			border: none;
			color: #fff;
		}
		.ant-btn:hover{
			color: #7a7878;
		}
	}
}
.ant-card.instore_card{
	color: #fff;
	.ant-card-body{
		background-color: #000;
		color: #fff;
		overflow-y: auto; 
		max-height: calc(100vh - 200px);
		-ms-verflow-style: none;
		border-radius: 25px;
		height: 300px;
		.ant-row{
			h2{
				color: #fff;
			}
		}
	}
	.ant-card-body::-webkit-scrollbar {
		display: none;
	}
}
.ant-modal.instore_modal{
	margin-top: -2rem; 
	padding: 0 2rem;
	color: #000;
	.ant-modal-close-x {
		margin: -5px 0 0 5px;
		color: #fff;
	}
	.ant-modal-body{
		background-color: #5f5e5e;
		color: #000;
		overflow-y: auto; 
		max-height: calc(100vh - 200px);
		-ms-verflow-style: none;
	}
	.ant-modal-body::-webkit-scrollbar {
		display: none;
	}
}
.user_points {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	padding: 20px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	width: 100%;
	max-width: 425px;
	img {
		width: 70px;
	}
	p {
		margin: 0;
		color: #6b6a6a;
	}
	h2 {
		margin: 0;
	}
	.points {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: 30px;
	}
}
.purchase_value {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	max-width: 425px;
	margin-top: 10px;
	border-bottom: 1px solid #fff;
	padding-bottom: 20px;
	p {
		margin: 0;
	}
	.divider {
		width: 1px;
		height: 70px;
		background-color: #fff;
	}
	.purchase,
	.membership {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p:first-child {
			color: #6b6a6a;
			font-size: 500;
		}
		p ~ p {
			margin-top: 10px;
			font-size: 600;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
.redeem_points {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-bottom: 1px solid #fff;
	padding-bottom: 20px;
	width: 100%;
	max-width: 425px;
	margin-top: 30px;
	div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		p {
			margin: 0;
		}
		p ~ p {
			color: #6b6a6a;
			font-size: 14px;
		}
	}
}
.browse_offer {
	margin-top: 20px;
	width: 100%;
	max-width: 450px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #fff;
	padding-bottom: 40px;
}
.top_deals {
	margin-top: 20px;
	width: 100%;
	max-width: 450px;
	p {
		margin: 0;
	}
}
.products_div {
	display: flex;
	overflow: scroll;
}
.popupmodal_{
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	height: 550px;
	p, h2, h3{
		color: #000;
	}
	h2{
		font-family: Inter;
		font-size: 24px;
		font-weight: 400;
		line-height: 36px;
		letter-spacing: 0em;
		background: #6B6A6A;
	}
	h3{
		font-size: 32px;
		font-weight: 600;
		line-height: 39px;
		letter-spacing: -0.05em;
	}
	p{
		font-size: 14px;
		font-weight: 600;
		line-height: 17px;
		letter-spacing: 0em;
		text-align: left;
	}
}
.popupcontent{
	text-align: center;
}
.ant-btn.popBtn{
	margin-top: 1.5rem;
	padding: 0.5rem 7rem 2rem;
}
.organisationCat{
	margin-top: 5rem;
}
.cart_animate{
	animation: basketwiggle 0.7s ease infinite;
}
@keyframes basketwiggle {
	0%{
		transform: rotate(0deg);
	}
	25%{
		transform: rotate(30deg);
	}
	50%{
		transform: rotate(-30deg);
	}
	75%{
		transform: rotate(15deg);
	}
	100%{
		transform: rotate(0deg);
	}
}