body {
	background-color: #131313;
	margin: 0;
}
#root { 
	background-color: #000;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
	color: #fff;
}

// iframe {
// 	z-index: -100 !important;
// }

.flex_center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex_center_column {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #000;
}

.container_main {
	max-width: 80%;
	margin-left: 10%;

	@media (max-width: 768px) {
		max-width: 100%;
		margin: 0 30px;
	}
	@media (max-width: 425px) {
		max-width: 100%;
		margin: 0 10px;
	}
}

.title_text {
	font-weight: 600;
	font-size: 24px;
}
.anticon-branches svg {
	color: #fff;
	font-weight: 800;
	scale: 1.5;
	cursor: pointer;
}

.footer_grey{
	background: #B3B2B5 !important;
}
.footer_div {
	// background: #000;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 80px;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 10;

	.nav_item {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		span {
			color: #adadad;
			font-weight: 600;
		}
		img {
			opacity: 0.5;
		}
		&.active {
			span {
				color: #fff;
			}
			img {
				opacity: 1;
			}
		}
	}
}

.ant-input-search {
	.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
		border-radius: 5px;
	}
	.ant-input-group-addon {
		display: none;
	}
}
