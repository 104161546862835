.detailsModal{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .ant-modal-body{
        p{
            color: #000;
            // color: #8a8080;
        }
    }
}
.radioBooking .ant-radio-wrapper span{
    color: #000 !important;
}
.serviceview{
    display: flex;
    align-items: top;
    gap: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    .serviceviewservice{
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 160px;
        }
        .serviceinitial{
            border: 1px solid #fff;
            border-radius: 8px;
            padding: 20px;
            display: flex;
            gap: 15px;
            .servicefes{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20%;
                .ant-image-img{
                    max-height: 100px;
                }
                image{
                    border-radius: 50%;
                }
            }
            .services{
                display: flex;
                flex-direction: column;
                // gap: 30px;
                width: 80%;
                .servicebelow{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .plusandminus{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        justify-content: center;
                    }
                    .selectbtn{
                        height: 52px;
                        width: 150px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 8px;
                        span{
                            text-transform: uppercase;
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        
    }
    .serviceviewtotal{
        width: 38%;
        transition: all 0.3s ease;
        border: 1px solid #fff;
        border-radius: 8px;
        padding: 20px 10px;
        min-height: 150px;
        height: fit-content;
        @media (max-width: 768px) {
            width: 100%;
        }
        .stitle{
            text-align: center;
        }
        .proceed{
            height: 52px;
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            text-align: center;
            span{
                text-transform: capitalize;
                font-size: 20px;
                font-weight: 600;
            }
        }
        .supDiv{
            display: flex;
            flex-direction: column;
            gap: 0px;
        }
        .selectedServicess{
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            p, h3{
                margin: 0;
            }
            .lowerdiv{
                display: flex;
                align-items: center;
                justify-content: space-between;                
            }
        }
    }
}
.paymentoptions{
    .ant-modal-body{
        text-align: center;
        h2, p, h1{
            color: #000;
        }
    }
}
.ussd_modal{
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 0.3rem;
}
.fixedPosition::-webkit-scrollbar{
    width: 5px;
}
.fixedPosition{
    position: fixed;
    right: 15px;
    width: 32% !important;
    top: 90px;
    max-height: 75vh;
    overflow-y: scroll;
    
    overflow-x: hidden;
    @media (max-width: 768px) {
        position: fixed;
        overflow-y: scroll;
        max-height: 250px;
        background-color: #000;
        bottom: 0;
        z-index: 100;
        // width: 95%;
        right: unset;
        width: 95% !important;
        top: unset;
    }
}
.ussdservice .ant-modal-body{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 1rem;
    text-align: center;
    margin-top: -1.3rem;
    margin-left: 0.7rem;
    .bank_codes{
        cursor: pointer;
        h4{
            text-align: center;
            color: #000;
            line-height: 15px;
            height: 23px;
            margin-bottom: 0.6rem;
        }
        img{
            height: 60px;
            width: 60px;
        }
        .ussd_modal{
            display: flex;
            text-align: center;
            justify-content: center;
            margin-top: 0.3rem;
        }
        p{
            color: #000;
            margin-bottom: 0rem;
        }
    }
}