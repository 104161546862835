.categories_list {
	width: 100%;
	max-width: 450px;
	.category_item {
		border-bottom: 1px solid #fff;
		padding-bottom: 10px;
		color: #fff;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		cursor: pointer;

		.checklist {
			width: 16px;
			height: 16px;
			margin-right: 10px;
			border-radius: 50%;
			background: #6b6a6a;
		}
	}
}

.category_title {
	padding: 0;
	margin: 30px 0 10px 0;
}
