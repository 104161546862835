.variants {
    padding: 5px 15px;
    .type {
      font-size: 14px;
    }
    .oi::-webkit-scrollbar {
      display: none;
    }
    .variant-wrapper {
      display: flex;
      gap: 0px 15px;
      flex-wrap: wrap;
      max-height: 150px;
      overflow: scroll;
      .variant {
        border: 1px solid rgb(217, 217, 217);
        // width: 140px;
        // h eight: 30px;
        padding: 10px;
        font-size: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 2px;
        cursor: pointer;
        opacity: 0.9;
      }
      .variant:hover {
        opacity: 1;
      }
      .selected {
        background: #b04176;
        color: white !important;
      }
    }
    .variant-wrapper::-webkit-scrollbar{
      display: none;
    }
    .clear-button {
      float: right;
      background: #b04176;
      color: #fff;
    }
  }