.modal_2{
    .md_1{
        display: flex;
        flex-direction: column;
        gap: 10px;
        p{
            color: #000;
        }
        div{
            p{
                margin-bottom: 0;
                text-align: center;
                margin-bottom: 15px;
            }
            .input-otp{
                input{
                    border-radius: 15px;
                }
            }
        }
        .btn-div{
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            margin-top: 10px;
            .ant-btn{
                width: 40%;
                height: 40px;
                border-radius: 8px;
                span{
                    color: #fff;
                }
            }
            p{
                margin-bottom: 0;
                text-align: center;
                margin-top: 15px;
                span{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}